import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-md-row h-100 w-100" }
const _hoisted_2 = { style: {"position":"relative"} }
const _hoisted_3 = {
  href: "#",
  class: "mb-12",
  style: {"position":"absolute","top":"20px","left":"20px"}
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "d-flex flex-grow-1 w-md-50 h-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "d-none d-md-flex flex-grow-1 flex-column flex-column-fluid w-md-50 h-20 h-md-100",
      style: _normalizeStyle(`background-image: url('./distribuidor/login_${_ctx.distribuidor.distribuidor}.jpg'); background-size: cover; background-position: right top;`)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, [
          _createElementVNode("img", {
            alt: "Logo",
            style: {"width":"90px","height":"90px"},
            src: _ctx.url
          }, null, 8, _hoisted_4)
        ])
      ])
    ], 4),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_view)
    ])
  ]))
}